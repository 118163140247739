let Organization = function(tokenClient, serviceAddr) {
	let TokenClient = tokenClient
	let ServiceBaseAddress = serviceAddr
	let OrganizationSession = 'OrganizationSession'
	/**
	 * 获取所有组织机构
	 * @param {any} success
	 * @param {any} error
	 */
	this.Hospitals = function(success, error) {
		var url = ServiceBaseAddress + '/api/Organization'
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}

	/**
	 * 查询机构详情
	 * @param organizationId
	 * @param success
	 * @param error
	 * @constructor
	 */
	this.OrgDetail = function(organizationId, success, error) {
		var url = ServiceBaseAddress + '/api/Organization/Detial/'+ organizationId
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	/**
	 * 获取机构二维码
	 * @param organizationId
	 * @param success
	 * @param error
	 * @constructor
	 */
	 this.QRCode = function(param, success, error) {
		var url = ServiceBaseAddress + '/api/WXQrCode/QRCode'
		TokenClient.Post(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			param,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	/**
	 * 查询组织机构详情
	 * @param {any} organizationId
	 * @param {any} success
	 * @param {any} error
	 */
	this.SubOrganz = function(organizationId, success, error) {
		var url = ServiceBaseAddress + '/api/Organization/Childrens/'+ organizationId
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.Department = function(organizationId, success, error) {
		var url = ServiceBaseAddress + '/api/Department/Organization/'
		if (organizationId) {
			url += organizationId
		} else {
			url += 0
		}
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.DepartmentTree = function (organizationId, isBusiness,success, error) {
		var url = ServiceBaseAddress + '/api/Department/Organization/Tree/'
		if (organizationId) {
			url += organizationId
		} else {
			url += 0
		}
		url += `/${isBusiness}`
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function (data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.SetDepartment = function(
		organizationId,
		departmentIds,
		success,
		error
	) {
		var url =
			ServiceBaseAddress +
			'/api/Department/Organization/' +
			organizationId
		TokenClient.Post(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			departmentIds,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.Enable = function(organizationId, success, error) {
		var url =
			ServiceBaseAddress + '/api/Organization/Enable/' + organizationId
		TokenClient.Put(url,true,{ 'Content-Type': 'application/json' },null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.Disable = function(organizationId, success, error) {
		var url =
			ServiceBaseAddress + '/api/Organization/Disable/' + organizationId
		TokenClient.Put(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	/**
	 * 添加医院
	 * @param {any} name 医院名称
	 * @param {any} address 医院地址
	 * @param {any} phone 医院联系方式
	 * @param {any} mail 医院电子邮箱
	 * @param {any} contractName 医院联系人
	 * @param {any} contractPhone 医院联系人电话
	 * @param {any} regionId 所在区域
	 * @param {any} parentId 父级医院
	 * @param {any} success
	 * @param {any} error
	 */
	this.AddHospital = function(item, success, error) {
		var url = ServiceBaseAddress + '/api/Organization'
		TokenClient.Post(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			item,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	/**
	 * 修改医院信息
	 * @param {any} organizationId
	 * @param {any} name 医院名称
	 * @param {any} address 医院地址
	 * @param {any} phone 医院联系方式
	 * @param {any} mail 医院电子邮箱
	 * @param {any} contractName 医院联系人
	 * @param {any} contractPhone 医院联系人电话
	 * @param {any} regionId 所在区域
	 * @param {any} parentId 父级医院
	 * @param {any} success
	 * @param {any} error
	 */
	this.EditHospital = function(item, success, error) {
		var url = ServiceBaseAddress + '/api/Organization'
		TokenClient.Put(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			item,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}

	/**
	 * 获取组织机构的有效期数据
	 * @param {any} organizationId
	 * @param {any} success
	 * @param {any} error
	 */
	this.Expiry = function(organizationId, success, error) {
		var url =
			ServiceBaseAddress + '/api/Organization/Expiry/' + organizationId
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	/**
	 * 查询指定组织机构信息，以树形结构返回上下级信息
	 * @param {any} success
	 * @param {any} error
	 */
	this.RefeHospital = function(organizationId,success, error) {
		var url = ServiceBaseAddress + '/api/Organization/Parents/'+organizationId
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.RotaHospital = function(organizationId, success, error) {
		var url =
			ServiceBaseAddress + '/api/Organization/Childrens/' + organizationId
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.RefeDepartment = function(organizationId, success, error) {
		var url =
			ServiceBaseAddress +
			'/api/Department/Organization/' +
			organizationId
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	/**
	 * 根据分类查询组织机构
	 * @param {any} organizationId
	 * @param {any} success
	 * @param {any} error
	 */
	// this.Hospital = function(organizationId, success, error) {
	// 	var url =
	// 		ServiceBaseAddress + '/api/Organization/Category/' + organizationId
	// 	TokenClient.Get(
	// 		url,
	// 		true,
	// 		null,
	// 		null,
	// 		function(data) {
	// 			if (success) {
	// 				var jsonObj = JSON.parse(data)
	// 				success(jsonObj)
	// 			}
	// 		},
	// 		error
	// 	)
	// }
	this.Hospital = function (productId, keyWord, success, error) {
		var url =ServiceBaseAddress + '/api/Organization/Product/' + productId
		if (keyWord) {
			url += `/${keyWord}`
		} else {
			url += '/%20'
		}
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function (data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.OrganizationManagerAdd = function(item, success, error) {
		var url = ServiceBaseAddress + '/api/Department/OrganizationManagerAdd'
			TokenClient.Post(
				url,
				true,
				{ 'Content-Type': 'application/json' },
				item,
				function(data) {
					if (success) {
						var jsonObj = JSON.parse(data)
						success(jsonObj)
					}
				},
				error
			)
	}
	this.OrganizationManagerRemove = function(organizationId, success, error) {
		var url =
			ServiceBaseAddress + '/api/Department/OrganizationManagerRemove/' + organizationId
		TokenClient.Delete(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}

	this.GetOrgInfo = function (success, error) {
		var url = ServiceBaseAddress + "/api/User/Info"
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function (data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.GetUserSign = function (success, error) {
		var url = ServiceBaseAddress + `/api/User/GetUserSign`
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function (data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.SetUserSign = function (signFile, success, error) {
		var url = ServiceBaseAddress + '/api/User/SetUserSign?signFile=' + signFile
		TokenClient.Post(url, true, { 'Content-Type': 'application/json' },null,
			function (data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}

	this.GetOrganizationListById = function (orgId,success, error) {
		var url = ServiceBaseAddress + `/api/Organization/GetOrganizationListById/${orgId}`
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function (data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	/**
     * haha
     */
	/*获取组织机构在session数据*/ 
	this.GetSessionOrganization = function() {
		var result = sessionStorage.getItem(OrganizationSession)
		if (result == null) {
			this.Hospitals(
				function(data) {
					/*保存组织机构到缓存*/
					sessionStorage.setItem(
						OrganizationSession,
						JSON.stringify(data.data)
					)
					result = data.data
				},
				function(err) {
					console.log(err)
				}
			)
		} else {
			result = JSON.parse(result)
		}
		return result
	}
}

export { Organization }
